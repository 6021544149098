//  Support.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Support() {
  return (
    <Container fluid style={{ backgroundColor: '#06141B', minHeight: '100vh', paddingTop: '50px', paddingBottom: '50px' }}>
      <Row className="justify-content-center">
        <Col md={6}> {/* 新增: 将Col的宽度从md={8}改为md={6} */}
          <Card style={{ backgroundColor: '#0d2230', border: '1px solid #1e3a4f' }}>
            <Card.Body style={{ padding: '40px' }}> {/* 新增: 增加内边距 */}
              <h2 style={{ color: '#85de65', marginBottom: '30px', textAlign: 'center' }}>Support</h2>
              <p style={{ color: '#CCD0CF', fontSize: '18px', marginBottom: '30px', textAlign: 'center' }}> {/* 新增: 增加下边距 */}
                Need help? We're here to assist you. Please contact us using one of the following methods:
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <p style={{ color: '#FFC107', fontSize: '20px', marginBottom: '15px' }}> {/* 新增: 增加间距 */}
                  Email: <a href="mailto:support@tradematch.cc" style={{ color: '#85de65' }}>support@tradematch.cc</a>
                </p>
                <p style={{ color: '#FFC107', fontSize: '20px', marginBottom: '15px' }}> {/* 新增: 增加间距 */}
                  Telegram: <a href="https://t.me/tmclient" target="_blank" rel="noopener noreferrer" style={{ color: '#85de65' }}>@tmclient</a>
                </p>
                <p style={{ color: '#FFC107', fontSize: '20px' }}>
                  Instagram: <a href="https://www.instagram.com/tradematchofficial" target="_blank" rel="noopener noreferrer" style={{ color: '#85de65' }}>@tradematchofficial</a>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Support;