// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#4A5C6A', color: '#CCD0CF', padding: '20px' }}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="text-center mb-3">
            <img src={`${process.env.PUBLIC_URL}/logo-icon2.png`} alt="Logo" style={{ width: '100px' }} />
          </Col>
          <Col xs={12} md={6} className="text-center mb-3">
            <p><strong>Contact Us</strong><br />
              Email: tradematch@gmx.com{' '}|{' '}Telegram: @tmclient</p>
          </Col>
          <Col xs={12} md={6} className="text-center mb-3">
            <p><strong>Disclaimer</strong><br />
              You must be at least 18 years old to play on Tradematch. If you violate our Terms & Conditions, your account may be restricted.</p>
          </Col>
          <Col xs={12} className="text-center">
            <p><strong>Copyright © 2024{' '}{' '}Champion Online LTD 128 City Road{' '}{' '}London EC1V 2NX CN: 15501411</strong></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
