// Drawer.js
import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

function ContactDrawer() {
    const [isOpen, setOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 500); 
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {!isOpen && (
                <IconButton onClick={toggleDrawer(true)} style={{ position: 'fixed', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
                    <img src={`${process.env.PUBLIC_URL}/openmenu-icon.png`} alt="Open Menu" style={{ width: 24, height: 24 }} />
                </IconButton>
            )}
            
            <Drawer 
                anchor="right" 
                open={isOpen} 
                onClose={toggleDrawer(false)} 
                variant="persistent" 
                ModalProps={{ keepMounted: true, BackdropProps: { style: { backgroundColor: 'transparent' } } }} 
                PaperProps={{
                    style: {
                        backgroundColor: '#CCD0CF',
                        color: '#515151',
                        width: '80%',
                        maxWidth: '220px',
                        boxShadow: 'none',
                        border: 'none',
                        position: 'fixed',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: 0,
                        height: 'auto',
                        maxHeight: '40%',
                    },
                    elevation: 0
                }}
                BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            >              
                <div
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    style={{ backgroundColor: '#CCD0CF', color: '#515151', padding: '10px' }}
                >
                    <IconButton onClick={toggleDrawer(false)} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <img src={`${process.env.PUBLIC_URL}/closemenu-icon.png`} alt="Close Menu" style={{ width: 24, height: 24 }} />
                    </IconButton>
                    <List>
                        <ListItem>
                            <ListItemText primary="Follow Us" style={{ textAlign: 'center', marginRight: 20 }} />
                        </ListItem>
                        <ListItem>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#515151' }}>
                                <img src={`${process.env.PUBLIC_URL}/X-icon.png`} alt="X" style={{ width: 24, height: 24, marginRight: 10 }} />
                                <ListItemText primary="X" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#515151' }}>
                                <img src={`${process.env.PUBLIC_URL}/Tiktok-icon.png`} alt="TikTok" style={{ width: 24, height: 24, marginRight: 10 }} />
                                <ListItemText primary="TikTok" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#515151' }}>
                                <img src={`${process.env.PUBLIC_URL}/Youtube-icon.png`} alt="YouTube" style={{ width: 24, height: 24, marginRight: 10 }} />
                                <ListItemText primary="YouTube" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#515151' }}>
                                <img src={`${process.env.PUBLIC_URL}/Facebook-icon.png`} alt="Facebook" style={{ width: 24, height: 24, marginRight: 10 }} />
                                <ListItemText primary="Facebook" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#515151' }}>
                                <img src={`${process.env.PUBLIC_URL}/Instagram-icon.png`} alt="Instagram" style={{ width: 24, height: 24, marginRight: 10 }} />
                                <ListItemText primary="Instagram" />
                            </a>
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

export default ContactDrawer;

