// Privacy.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function PrivacyModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton style={{ backgroundColor: '#0d2230', borderBottom: '1px solid #1e3a4f' }}>
        <Modal.Title style={{ color: '#85de65' }}>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#0d2230', color: '#CCD0CF', maxHeight: '70vh', overflowY: 'auto' }}>
        <h5 style={{ color: '#85de65' }}>Introduction</h5>
        <p>TRADEMATCH takes your privacy seriously and is committed to protecting it through our compliance with this policy.</p>
        
        <h5 style={{ color: '#85de65' }}>Information We Collect</h5>
        <p>We collect information from and about users of the App:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically when you use the App.</li>
          <li>Automatically when you execute transactions on the App.</li>
        </ul>

        <h5 style={{ color: '#85de65' }}>How We Use Your Information</h5>
        <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
        <ul>
          <li>To provide you with the App and its contents, and any other information, products or services that you request from us.</li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
        </ul>

        <h5 style={{ color: '#85de65' }}>Data Security</h5>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>

        <h5 style={{ color: '#85de65' }}>Changes to Our Privacy Policy</h5>
        <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page.</p>

        <h5 style={{ color: '#85de65' }}>Contact Information</h5>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
        <p>Email: tradematch@gmx.com</p>
        <p>Telegram: @tmclient</p>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: '#0d2230', borderTop: '1px solid #1e3a4f' }}>
        <Button variant="secondary" onClick={handleClose} style={{ backgroundColor: '#1e3a4f', borderColor: '#1e3a4f' }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyModal;