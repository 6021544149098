// App.js

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Advertisement from './components/Advertisement';
import DownloadButtons from './components/DownloadButtons';
import Footer from './components/Footer';
import ContactDrawer from './components/Drawer';
import Support from './components/Support'; 

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Advertisement />
              <DownloadButtons />
            </>
          } />
          <Route path="/support" element={<Support />} /> 
        </Routes>
        <ContactDrawer />
        <Footer />
      </div>
    </Router>
  );
}

export default App;