// Advertisement.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Advertisement() {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [imageError, setImageError] = useState(false);

  // 新增：使用 useMemo 来记忆 ads 数组
  const ads = useMemo(() => [
    `${process.env.PUBLIC_URL}/AD/AD1.png`,
    `${process.env.PUBLIC_URL}/AD/AD2.png`,
    `${process.env.PUBLIC_URL}/AD/AD3.png`,
    `${process.env.PUBLIC_URL}/AD/AD4.png`,
    `${process.env.PUBLIC_URL}/AD/AD5.png`
  ], []); // 空依赖数组，因为这些路径不会改变

  const handleImageError = useCallback(() => {
    console.error("Image failed to load:", ads[currentAdIndex]);
    setImageError(true);
  }, [ads, currentAdIndex]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [ads]);

  return (
    <div style={{
      backgroundColor: '#06141B',
      color: '#9BA8AB',
      padding: '50px',
      marginTop: '0px',
      marginBottom: '30px',
      width: '100%',
      position: 'relative',
    }}>
      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col xs={12}>
            <div style={{
              width: '100%',
              height: '0',
              paddingBottom: '42.85%',
              position: 'relative',
              overflow: 'hidden',
            }}>
              {imageError ? (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                }}>
                  Image failed to load
                </div>
              ) : (
                <img
                  src={ads[currentAdIndex]}
                  alt={`Advertisement ${currentAdIndex + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    position: 'absolute',
                    top: '0',
                    left: '0'
                  }}
                  onError={handleImageError}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '10px', 
            left: '50%',
            transform: 'translateX(-50%)',
          }}>
            {ads.map((_, idx) => (
              <div
                key={idx}
                onClick={() => setCurrentAdIndex(idx)}
                style={{
                  height: '10px',
                  width: '10px',
                  borderRadius: '50%',
                  backgroundColor: currentAdIndex === idx ? 'white' : '#ccc',
                  margin: '0 5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              />
            ))}
          </Col>
        </Row>
      </Container>
      <style>{`
        @media (max-width: 768px) {
          div[style*='padding: 50px'] {
            padding: 10px !important; 
          }
          div[style*='paddingBottom: 42.85%'] {
            padding-bottom: 56.25% !important;
          }
          div[style*='bottom: 10px'] {
            bottom: 0px !important;
          }
        }
      `}</style>
    </div>
  );
}

export default Advertisement;